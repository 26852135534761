<template>
  <div>
    <div class="c-form-wrapper--small">
      <div class="c-form" v-if="selectedConfigurationData.length && wingoMarketIdConfiguration">
        <div class="c-form__row">
          <generic-form-group 
            :label="wingoMarketIdConfiguration.label"
            :disabled="!hasWritePermision || !selectedConfigurationData[findIndexByKey(wingoMarketIdConfiguration)].enabled && type !== selectedType"  
          >
            <md-field class="c-select-field c-select">
              <label>{{ selectedMarketId ? '' : wingoMarketIdConfiguration.label }}</label>
              <md-input
                v-model="selectedMarketId" 
                :name="wingoMarketIdConfiguration.label"
                :type="'number'"
              />
            </md-field>
          </generic-form-group>
          <div v-if="type !== selectedType" class="c-form__checkbox">
            <md-checkbox v-model="selectedConfigurationData[findIndexByKey(wingoMarketIdConfiguration)].enabled" :disabled="!hasWritePermision" class="c-form-checkbox__checkmark" /><span>Enable Configuration </span>
          </div>
        </div>
        <div class="c-form__row">
          <generic-form-group 
            :label="wingoCasinoProviderIdConfiguration.label"
            :disabled="!hasWritePermision || !selectedConfigurationData[findIndexByKey(wingoCasinoProviderIdConfiguration)].enabled && type !== selectedType"  
          >
            <md-field class="c-select-field c-select">
              <label>{{ selectedCasinoProviderId ? '' : wingoCasinoProviderIdConfiguration.label }}</label>
              <md-input
                v-model="selectedCasinoProviderId" 
                :name="wingoCasinoProviderIdConfiguration.label"
                :type="'number'"
              />
            </md-field>
          </generic-form-group>
          <div v-if="type !== selectedType" class="c-form__checkbox">
            <md-checkbox v-model="selectedConfigurationData[findIndexByKey(wingoCasinoProviderIdConfiguration)].enabled" :disabled="!hasWritePermision" class="c-form-checkbox__checkmark" /><span>Enable Configuration </span>
          </div>
        </div>
        <div class="c-form__row">
          <generic-form-group 
            :label="wingoCasinoGameIdConfiguration.label"
            :disabled="!hasWritePermision || !selectedConfigurationData[findIndexByKey(wingoCasinoGameIdConfiguration)].enabled && type !== selectedType"  
          >
            <md-field class="c-select-field c-select">
              <label>{{ selectedCasinoGameId ? '' : wingoCasinoGameIdConfiguration.label }}</label>
              <md-input
                v-model="selectedCasinoGameId" 
                :name="wingoCasinoGameIdConfiguration.label"
                :type="'number'"
              />
            </md-field>
          </generic-form-group>
          <div v-if="type !== selectedType" class="c-form__checkbox">
            <md-checkbox v-model="selectedConfigurationData[findIndexByKey(wingoCasinoGameIdConfiguration)].enabled" :disabled="!hasWritePermision" class="c-form-checkbox__checkmark" /><span>Enable Configuration </span>
          </div>
        </div>
      </div>
    </div>
    <div class="u-flex-justify-end u-mr-md u-mb-md">
      <div class="u-mr-md">
        <generic-button variation="red" :disabled="!hasWritePermision" @click="updateSettings(true)">
          Clear All
        </generic-button>
      </div>
      <generic-button variation="red" :disabled="!hasWritePermision" @click="updateSettings">
        Update
      </generic-button>
    </div>
  </div>
</template>
  <script>
  import GenericButton from "../../../shared/buttons/GenericButton";
  import get from "lodash/get";
  import findIndex from 'lodash/findIndex';
  import cloneDeep from 'lodash/cloneDeep';
  import GenericFormGroup from "../../../shared/form-group/GenericFormGroup";
  import { mapActions, mapGetters } from "vuex";
  import { configurationsService } from "../../../services/configurations";
  import { hasWritePermission } from '../../../helpers/permission';
  
  export default {
    name: "WingoGeneralSettings",
    components: {
      GenericButton,
      GenericFormGroup,
    },
  
    props: {
      type: {
        type: String,
        required: true,
      },
    },
  
    data() {
      return {
        selectedType: "market",
        selectedMarketId: null,
        selectedCasinoProviderId: null,
        selectedCasinoGameId: null,
        clearAll: false,
        hasWritePermision: false,
        selectedConfigurationData: [],
      };
    },
  
    computed: {
      ...mapGetters("company", [
        "getApiConfigurationsData",
        "getApiMarketConfigurationsData",
        "getApiBetshopConfigurationsData",
      ]),
      ...mapGetters("additionalTemplate", ["getAllAdditionalTemplateKeys"]),
  
      getSelectedMarketId() {
        const index = findIndex(this.selectedConfigurationData, { key: 'wingo_market_id'} );

        return get(this.selectedConfigurationData[index], 'value', '');
      },

      getSelectedCasinoProviderI() {
        const index = findIndex(this.selectedConfigurationData, { key: 'casino_provider_id'} );

        return get(this.selectedConfigurationData[index], 'value', '');
      },

      getSelectedCasinoGameId() {
        const index = findIndex(this.selectedConfigurationData, { key: 'casino_game_id'} );

        return get(this.selectedConfigurationData[index], 'value', '');
      },
  
      wingoMarketIdConfiguration() {
        const index = findIndex(this.getApiConfigurationsData, { key: 'wingo_market_id' });
  
        return this.getApiConfigurationsData[index]
      },

      wingoCasinoProviderIdConfiguration() {
        const index = findIndex(this.getApiConfigurationsData, { key: 'casino_provider_id' });
  
        return this.getApiConfigurationsData[index]
      },

      wingoCasinoGameIdConfiguration() {
        const index = findIndex(this.getApiConfigurationsData, { key: 'casino_game_id' });
  
        return this.getApiConfigurationsData[index]
      },
  
    },
  
    async created() {
      this.setConfigurations();
      this.selectedMarketId = this.getSelectedMarketId;
      this.selectedCasinoProviderId = this.getSelectedCasinoProviderI;
      this.selectedCasinoGameId = this.getSelectedCasinoGameId;
      this.checkPermissions();
    },
  
    methods: {
      ...mapActions("response", [
        "handleSuccess",
        "handleError",
        "setGlobalError",
      ]),
      ...mapActions("company", [
        "updateMarketConfigurations",
        "updateBetshopConfigurations",
      ]),
      ...mapGetters('account', ['user', 'userHierarchyWriteableSections']),
  
      async updateSettings(clearAll) {
        if (clearAll) {
          this.selectedMarketId = null;
          this.selectedCasinoGameId = null;
          this.selectedCasinoProviderId = null;
          this.clearAll = true;
        }

        this.type === this.selectedType
          ? await this.updateMarket()
          : await this.updateBetshop();
        this.handleSuccess({ message: "Successfuly updated configurations!" });
        this.clearAll = false;
        this.$emit('canChangeTab', false);
      },
  
      async updateMarket() {
        const { data } = await configurationsService.updateMultipleMarketConfigurations(
          get(this.$route, "params.marketId"),
          this.selectedConfigurationData.map((config) => ({
            ...config,
            value: this.prepareValues(config)
          }))
        );
        this.updateMarketConfigurations(data);
      },
  
      async updateBetshop() {
        const { data } = await configurationsService.updateMultipleBetshopConfigurations(
          get(this.$route, "params.betshopId"),
          this.selectedConfigurationData.map((config) => ({
            ...config,
            value: this.prepareValues(config)
          }))
        );
        this.updateBetshopConfigurations(data);
      },
  
      prepareValues(config) {
        if (config.key === 'wingo_market_id' ) {
          return JSON.stringify(this.clearAll ? '' : this.selectedMarketId);
        }

        if (config.key === 'casino_provider_id' ) {
          return JSON.stringify(this.clearAll ? '' : this.selectedCasinoProviderId);
        }

        if (config.key === 'casino_game_id' ) {
          return JSON.stringify(this.clearAll ? '' : this.selectedCasinoGameId);
        }
  
        return JSON.stringify(get(config, 'value'))
      },
  
      findIndexByKey(configuration) {
        return this.selectedConfigurationData.findIndex(
          (config) => config.configurationId === configuration.id
        );
      },
  
      onChangeSectionOrTamplate() {
        this.$emit('canChangeTab', true);
      },
  
      checkPermissions() {
        this.hasWritePermision = hasWritePermission(
          this.user(),
          this.userHierarchyWriteableSections(),
          `wingo-general-settings-${this.type}`
        );
      },
  
      setConfigurations() {
        this.selectedConfigurationData = 
          this.type === this.selectedType ? 
            cloneDeep(this.getApiMarketConfigurationsData) : 
            cloneDeep(this.getApiBetshopConfigurationsData);
      },
    },
  };
  </script>
  